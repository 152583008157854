import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {SsoProvider} from '../../../../../../../src/app/enums/sso-provider.enum';
import {LsAuthResponse} from '../../models/ls-auth-response';
import {ScreenType, UserType} from '../../../../../../../src/app/enums';
import {IdsTokenInfo} from '../../models/ids-token-info';
import {LsMpClassResponse} from '../../models/ls-mp-class-response';
import {ScreenSessionResponse} from '../../../../../../../src/app/models';
import {AdminType} from '../../../../../../../src/app/enums/admin-type.enum';
import {SchoolMetaDataResponse} from '../../../../../../../src/app/models/school-meta-data-response';
import {UserClassesData} from '../../models/user-classes-data';
import {MpAuthInfo} from '../../models/mp-auth-info';
import {UserResponseModel} from '../../models/user-response-model';
import {UserUpdateModel} from '../../models/user-update-model';
import {StudioSchoolResponse} from '../../models/studio-school-response';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Authenticate': props<{customerId: number, username: string, password: string}>(),
    'AuthenticateSSO': props<{ssoProvider: SsoProvider, token?: string}>(),
    'AuthenticateLSMP': props<{token: string, refresh?: boolean}>(),
    'Authenticate Success': props<{ idsAccessToken: string, idsTokenInfo: IdsTokenInfo, lsAuthResponse: LsAuthResponse, mpAuthResponse: MpAuthInfo }>(),
    'Authenticate Failure': props<{ error: HttpErrorResponse }>(),
    'Auth Flow Completed': emptyProps(),
    'Online Authenticate': emptyProps(),
    'Logout': emptyProps(),
    'Logout Success': emptyProps(),
    'Logout MP Manager User': emptyProps(),
    'Logout MP Manager User Success': emptyProps(),
    'Logout MP Manager User Failure': props<{ error: HttpErrorResponse }>(),
    'Set Logged In As': emptyProps()
  }
});

export const UserSchoolsActions = createActionGroup({
  source: 'User Schools',
  events: {
    'Load User Schools': emptyProps(),
    'Load User Schools Success': props<{schools: StudioSchoolResponse[]}>(),
    'Load User Schools Failure': props<{error: HttpErrorResponse}>()
  }
});

export const SetupUserSchoolActions = createActionGroup({
  source: 'Setup User School',
  events: {
    'Setup User School': props<{ schoolId: string, isAllSelected: boolean, preSelectMPClassId?: number }>(),
    'Setup User School Success': props<{school: StudioSchoolResponse, metaData: SchoolMetaDataResponse, isAllSelected: boolean}>(),
    'Setup User School Failure': props<{error: HttpErrorResponse}>(),
    'Select All Schools': emptyProps(),
  }
});

export const UserClassesAction = createActionGroup({
  source: 'User Classes',
  events: {
    'Load User Classes': props<{id: string, userType: UserType, adminType: AdminType, schoolId: string, mpSchoolId: number, preSelectMPClassId: number} | undefined>(),
    'Load User Classes Success': props<{userClassesData: UserClassesData}>(),
    'Load User Classes Failure': props<{error: HttpErrorResponse}>(),
    'Select User Class': props<{ selectedClass: LsMpClassResponse, isAllSelected: boolean}>(),
    'Select All Classes': emptyProps(),
  }
});

export const UserSessionActions = createActionGroup({
  source: 'User Session',
  events: {
    'Start User Session': props<{userGroupId: string}>(),
    'Start User Session Success': props<{sessionId: string}>(),
    'Start User Session Failure': props<{error: HttpErrorResponse}>(),
    'End User Session': props<{logout?: boolean}>(),
    'End User Session Success': emptyProps(),
    'End User Session Failure': props<{error: HttpErrorResponse}>(),
  }
});

export const ScreenSessionActions = createActionGroup({
  source: 'Screen Session',
  events: {
    'Log Screen Session': props<{screenType: ScreenType}>(),
    'Log Screen Session Success': props<{data: ScreenSessionResponse}>(),
    'Log Screen Session Failure': props<{error: HttpErrorResponse}>()
  }
});

export const UpdateAuthDataActions = createActionGroup({
  source: 'Update Auth Data',
  events: {
    'Update MP Email': props<{email: string}>()
  }
});

export const CheckTokenActions = createActionGroup({
  source: 'Check Token',
  events: {
    'Check Token Validation': props<{token: string}>(),
    'Check Token Validation Success': props<{user: UserResponseModel}>(),
    'Check Token Validation Failure': props<{error: HttpErrorResponse}>()
  }
});

export const ResetPasswordActions = createActionGroup({
  source: 'Reset Password',
  events: {
    'Reset Password': props<{newPassword: string, token: string}>(),
    'Reset Password Success': emptyProps(),
    'Reset Password Failure': props<{error: HttpErrorResponse}>()
  }
});

export const SetupAccountActions = createActionGroup({
  source: 'Setup Account',
  events: {
    'Setup Account': props<{request: UserUpdateModel, token: string}>(),
    'Setup Account Success': emptyProps(),
    'Setup Account Failure': props<{error: HttpErrorResponse}>(),
    'Resend Setup Account': props<{email: string}>(),
    'Resend Setup Account Success': emptyProps(),
    'Resend Setup Account Failure': props<{error: HttpErrorResponse}>()
  }
});

export const SignupAccountActions = createActionGroup({
  source: 'Signup Account',
  events: {
    'Signup Account': props<{email: string, schoolId: number}>(),
    'Signup Account Success': emptyProps(),
    'Signup Account Failure': props<{error: HttpErrorResponse}>(),
  }
});

export const ApiVersionActions = createActionGroup({
  source: 'API Version',
  events: {
    'Load API Version': emptyProps(),
    'Load API Version Success': props<{ data: string }>(),
    'Load API Version Failure': props<{error: HttpErrorResponse}>(),
  }
});
